import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

// Chakra imports
import { Box,  Flex, Grid,  Text, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink } from '@chakra-ui/react'

import { AuthContext } from 'context/auth-context';

//Types
import { iAwards } from 'types/iAwards';
import { iVoting } from 'types/iVoting';
import { iAwardsCategories } from 'types/iAwardsCategories';
import { iNominee } from 'types/iNominee';

//Services
import { 
	memoizedGetAwardsCategories, getNomineesByCategory, memoizedGetSingleItem, getVotesForUser} from 'services/awardsService';

//Icons
import { ArrowBackIcon } from '@chakra-ui/icons';

// Custom components
import GameNominee from 'components/card/GameNominee';
import RoomNominee from 'components/card/RoomNominee';
import AwardCategoryBanner from './components/AwardCategoryBanner';
import SideVoting from '../invitations/components/SideVoting';


export default function VoteCategory() {
	const params: any = useParams();
	const awardId: string = params['award_id'] || "";
	const categoryId: string = params['category_id'] || "";


	const { currentUser, currentProfile } = useContext(AuthContext);	

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	
	const [nominees, setNominees]: [iNominee[], (awards: iNominee[]) => void] = useState();
	const [award, setAward]: [iAwards, (award: iAwards) => void] = useState();
	const [awardCategry, setAwardCategory]: [iAwardsCategories, (awards: iAwardsCategories) => void] = useState();
	const [votes, setVotes]: [iVoting, (votes: iVoting) => void] = useState();
	const [variants, setVariants]: [any, (variants: any) => void] = useState({});
	// Define the callback the parent passes to the child. 
	const [votingsValue, setVotingsValue] = useState(null);
	const handleChangesInChild = (value: any) => {
		setVotingsValue(value);
	}

	const resolvePosition = (nomineeId: string) => {
		if (nomineeId === votes?.first) {
			return 'gold';
		} else if (nomineeId === votes?.second) {
			return 'silver';
		} else if (nomineeId === votes?.third) {
			return 'bronze';
		} else {
			return null;
		}
	}

	useEffect(() => {		
			
			getNomineesByCategory(currentUser, awardId, categoryId).then((nominees: iNominee[]) => {
				setNominees(nominees);
			});

			memoizedGetSingleItem(currentUser, awardId).then((award: iAwards) => {
				setAward(award);
			});
			memoizedGetAwardsCategories(currentUser, awardId).then((awardsCategories: iAwardsCategories[]) => {
				setAwardCategory(awardsCategories.find((category: iAwardsCategories) => category.id === categoryId));
			});
			
			getVotesForUser(currentUser, awardId).then((votes: any) => {
				setVotes(votes[categoryId]);
			});

			if (votingsValue) {
				const tmpVariants: any = {};
				tmpVariants[votingsValue?.votes?.first] = 'gold';
				tmpVariants[votingsValue?.votes?.second] = 'silver';
				tmpVariants[votingsValue?.votes?.third] = 'bronze';
				setVariants(tmpVariants);
			}

	}, [ currentProfile, currentUser, awardId, categoryId, votingsValue]);

	return (
		<Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
			{/* Main Fields */}		
			<Flex mb="50px">
				<ChakraLink as={ReactRouterLink} to={`/awards/vote/${awardId}`}>
					<ArrowBackIcon/>Volver al listado de categorías
				</ChakraLink>
			</Flex>

			{ award ? <AwardCategoryBanner award={award} category={awardCategry}/> : null }
			
			<Grid
				mb='20px'
				gridTemplateColumns={{ xl: 'repeat(3, 1fr)', '2xl': '1fr 0.46fr' }}
				gap={{ base: '20px', xl: '20px' }}
				display={{ base: 'block', xl: 'grid' }}>
				<Flex flexDirection='column' gridArea={{ xl: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }} >
					<Flex direction='column'>
						<Flex
							mt='45px'
							mb='20px'
							justifyContent='space-between'
							direction={{ base: 'column', md: 'row' }}
							align={{ base: 'start', md: 'center' }}>
							<Text color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
								Nominados
							</Text>
							
						</Flex>
						
						<SimpleGrid columns={{ base: 1, md: 4 }} gap='20px'>
							{nominees?.map((nominee: iNominee) => (	
							(nominee.type === 'game')?
								<GameNominee
									variant={(variants.hasOwnProperty(nominee.id)?variants[nominee.id]:null) || resolvePosition(nominee.id)}
									nominee={nominee}
								/>
							:
								<RoomNominee 
									variant={(variants.hasOwnProperty(nominee.id)?variants[nominee.id]:null) || resolvePosition(nominee.id)}
									room={nominee.room}
								/>
							))}
						</SimpleGrid>
					
					</Flex>
				</Flex>
				<Flex flexDirection='column' gridArea={{ xl: '1 / 3 / 2 / 4', '2xl': '1 / 2 / 2 / 3' }}>
				<Flex
					mt='45px'
					mb='20px'
					justifyContent='space-between'
					direction={{ base: 'column', md: 'row' }}
					align={{ base: 'start', md: 'center' }}>
					
				</Flex>
				<SideVoting
					nominees={nominees}
					votings={votes}
					gridArea={{
						base: '3 / 1 / 4 / 2',
						lg: '2 / 1 / 3 / 3',
						'2xl': '1 / 3 / 2 / 4'
					}}
					onChangesInChild={handleChangesInChild}
				/>
					
				</Flex>
			
		</Grid>
		{/* Delete Product */}
	</Box>
	);
}
