// Chakra imports
import { Flex, useColorModeValue } from '@chakra-ui/react';

// Custom components
import { Image } from '@chakra-ui/react';
import { HSeparator } from 'components/separator/Separator';

import fobosLogoWhite from 'assets/img/fobos-logo-white.png';
import fobosLogoBlack from 'assets/img/fobos-logo-black.png';

export function SidebarBrand() {
	//   Chakra color mode
	//let logoColor = useColorModeValue('navy.700', 'white');
	const adminLogo = useColorModeValue(fobosLogoBlack, fobosLogoWhite);

	return (
		<Flex alignItems='center' flexDirection='column'>
			<Image src={adminLogo} alt="Fobos - El siguiente nivel" w={{ base: '80%', '3xl': '100%' }} maxW={`80%`} />
			<HSeparator mb='20px' />
		</Flex>
	);
}

export default SidebarBrand;
