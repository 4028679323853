// Chakra imports
import { Box, Icon, Image, SimpleGrid, useColorModeValue } from '@chakra-ui/react';

// Custom components
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
//import { MdOutlineRoomPreferences, MdHomeWork } from 'react-icons/md';
import { GiPuzzle } from "react-icons/gi";
import { IoAnalyticsOutline, IoHeart } from 'react-icons/io5';
import { HiOutlineUsers } from 'react-icons/hi';
import { useContext, useEffect, useState } from 'react';
//import { getSingleItem } from 'services/metricsService';
import { AuthContext } from 'context/auth-context';
//import { iMetric } from 'types/iMetric';
//import { iProfile } from 'types/iProfile';
import {  memoizedGetFullList } from 'services/awardsService';
//import TeamsMembership from '../invitations/components/TeamsMembership';
//import TeamsInvites from '../invitations/components/TeamsInvites';
import { iAwards } from 'types/iAwards';
import { iMetric } from 'types/iMetric';
import Course from 'components/card/Course';
import { memoizedGetSingleItem } from 'services/metricsService';
//import { iAwardsCategories } from 'types/iAwardsCategories';

export default function MainDashboard() {
	const { currentUser, currentProfile } = useContext(AuthContext);	

	// Chakra Color Mode
	const brandColor = useColorModeValue('brand.500', 'white');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

	
	const [userAwards, setuserAwards]: [iAwards[], (awards: iAwards[]) => void] = useState();
	const [userMetrics, setUserMetrics]: [iMetric, (metrics: iMetric) => void] = useState(); 

	useEffect(() => {		

			memoizedGetSingleItem(currentUser).then((metrics: iMetric) => {
				setUserMetrics (metrics);
			})

			memoizedGetFullList(currentUser).then((awards: iAwards[]) => {
				setuserAwards(awards);
			}	);
	}, [currentProfile, currentUser, userAwards, userMetrics]);

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<SimpleGrid id="statistics" columns={{ base: 1, md: 2, lg: 4, '2xl': 4 }} gap='20px' mb='20px'>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={GiPuzzle} color={brandColor} />}
						/>
					}
					name='Partidas jugadas'
					value={userMetrics?.played_games || 0}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={IoAnalyticsOutline} color={brandColor} />}
						/>
					}
					name='Partidas valoradas'
					value={userMetrics?.rated_games || 0}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={IoHeart} color={brandColor} />}
						/>
					}
					name='Juegos guardados'
					value={ userMetrics?.wishlist_items || 0}
				/>
				
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={HiOutlineUsers} color={brandColor} />}
						/>
					}
					name='Equipos'
					value={ userMetrics?.teams || 0}
				/>
			</SimpleGrid>
			<SimpleGrid columns={1}  gap='20px' >
					{userAwards?.map((award: iAwards) => (	
						<Course
							//bgBox='linear-gradient(115.07deg, #29E9F5 -9.41%, #7A64FF 28.04%, #FF508B 71.85%, #FD6D53 112.49%, #FD6D53 112.49%)'
							bgBox='transparent'
							//icon={<Icon as={IoLogoInstagram} color='white' w='100px' h='100px' />}
							icon={<Image src={process.env.REACT_APP_CDN_PUBLIC_IMAGES + "uploads/awards/" + award.logo} maxH={270} maxW={270}/>}
							title={award.name}
							desc={award.description}
							day={award.start_date}
							date={award.end_date}
							time='15 min'
							id={award.id}
						/>

					))}
			</SimpleGrid>
		</Box>
	);
}
