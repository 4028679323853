import { User } from "firebase/auth";
import { iMetric } from "types/iMetric";
import memoizee from "memoizee";
import { getOptions } from "./config";

async function getSingleItem(currentUser: User): Promise<iMetric> {

    const options = await getOptions(currentUser, 'get');

    return fetch(process.env.REACT_APP_FOBOS_API_HOST + '/me/metrics', options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        });
}

export { getSingleItem }

export const memoizedGetSingleItem = memoizee(getSingleItem, { promise: true, maxAge: 7*24*3600});