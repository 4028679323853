import { useHistory } from 'react-router-dom';
// Chakra imports
import {   Box, Button, Flex,  Image, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';

export default function Category(props: {
	image: string;
	name: string;
	id: string;
	voted: boolean;
	awardId: string;
}) {
	const { image, name, id, voted, awardId} = props;
	const history = useHistory();
	//const [ like, setLike ] = useState(false);
	//const textColor = useColorModeValue('navy.700', 'white');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	//const textColorBid = useColorModeValue('brand.500', 'white');

	const goToCategory = (awardId: string, categoryId: string) => {
		history.push(`/awards/vote/${awardId}/category/${categoryId}`)
	}


	return (
		<Card p='20px'>
			<Flex direction={{ base: 'column' }} justify='center'>
				<Box mb={{ base: '20px', '2xl': '20px' }} position='relative'>
					<Image
						src={image}
						w={{ base: '100%', '3xl': '100%' }}
						h={{ base: '100%', '3xl': '100%' }}
						borderRadius='20px'
					/>
				</Box>
				<Flex flexDirection='column' justify='space-between' h='100%'>
					<Flex
						justify='space-between'
						direction={{
							base: 'row',
							md: 'column',
							lg: 'row',
							xl: 'column',
							'2xl': 'row'
						}}
						mb='auto'>
						<Flex direction='column'>
							<Text
								color={textColor}
								fontSize={{
									base: 'xl',
									md: 'lg',
									lg: 'lg',
									xl: 'lg',
									'2xl': 'md',
									'3xl': 'lg'
								}}
								mb='5px'
								fontWeight='bold'
								me='14px'>
								{name}
							</Text>
						</Flex>
					</Flex>
					<Flex
						align={{
							base: 'center',
							md: 'start',
							lg: 'center',
							xl: 'start',
							'2xl': 'center'
						}}
						justify='space-between'
						direction={{
							base: 'row',
							md: 'column',
							lg: 'row',
							xl: 'column',
							'2xl': 'row'
						}}
						mt='25px'>
						
							<Button
								variant={(voted)?'brand':'darkBrand'}
								color='white'
								fontSize='sm'
								fontWeight='500'
								borderRadius='70px'
								px='24px'
								py='5px'
								onClick={() => goToCategory(awardId, id)}>								
								{(voted)?`Ver votos`: `Votar`}
								
							</Button>

					</Flex>
				</Flex>
			</Flex>
		</Card>
	);
}
