// Import Icons
import { Icon } from '@chakra-ui/react';
import { MdStar, MdCategory, MdLock } from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/awards/default';
import SignIn from 'views/auth/signIn';
import Vote from 'views/awards/vote';
import VoteCategory from 'views/awards/vote/VoteCategory';

const routes = [
	{
		name: 'Votaciones',
		longName: 'Votaciones',
		layout: '/awards',
		path: '/default',
		icon: <Icon as={MdStar} width='20px' height='20px' color='inherit' />,
		component: MainDashboard
	}
];

export default routes;


const deepRoutes = [
	{
		name: 'Sign In',
		layout: '/auth',
		path: '/sign-in',
		icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
		component: SignIn
	},
	{
		name: 'Register',
		layout: '/auth',
		path: '/register',
		icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
		component: SignIn
	},
	{
		name: 'Reset Password',
		layout: '/auth',
		path: '/reset-password',
		icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
		component: SignIn
	},
	{
		name: 'Votar en Categoria',
		longName: 'Votar en Categoria',
		layout: '/awards',
		path: '/vote/:award_id/category/:category_id',
		icon: <Icon as={MdCategory} width='20px' height='20px' color='inherit' />,
		component: VoteCategory,
		secondary: true
	},
	{
		name: 'Votaciones',
		longName: 'Votaciones',
		layout: '/awards',
		path: '/vote/:award_id',
		icon: <Icon as={MdCategory} width='20px' height='20px' color='inherit' />,
		component: Vote,
		secondary: true
	},
	
];

export { deepRoutes };