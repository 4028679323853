import { mode } from '@chakra-ui/theme-tools';
const Card = {
	baseStyle: (props: any) => ({
		p: '20px',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		position: 'relative',
		borderRadius: '20px',
		minWidth: '0px',
		wordWrap: 'break-word',
		bg: mode('#ffffff', 'fobos.cardGrey')(props),
		backgroundClip: 'border-box'
	}),
	variants: {
		gold: (props: any) => ({
			bg: 'fobos.gold',
			color: 'white',
		}),
		silver: (props: any) => ({
			bg: 'fobos.silver',
			color: 'white',
		}),
		bronze: (props: any) => ({
			bg: 'fobos.bronze',
			color: 'white',
		})


	}
};

export const CardComponent = {
	components: {
		Card
	}
};
