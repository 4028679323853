import { User } from "firebase/auth";
import memoizee from "memoizee";
import { getOptions } from "./config";
import { iVoting } from "types/iVoting";

async function getFullList(currentUser: User, fields?: string) {

    const options = await getOptions(currentUser, 'get');

    let result = await fetch(process.env.REACT_APP_FOBOS_API_HOST + '/awards/active', options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data.map((item: any) => ({ ...item, voted: false }));
        });

    return result;
}

export { getFullList };

async function getAwardsCategories(currentUser: User, awardId: string) {

    const options = await getOptions(currentUser, 'get');

    const voteResult = await getVotesForUser(currentUser, awardId);

    let result = await fetch(`${process.env.REACT_APP_FOBOS_API_HOST}/awards/${awardId}/categories`, options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            let processedData = data.map((item: any) => ({ ...item, voted: false }));

            processedData.forEach((item: any) => {
                
                if (voteResult){
                    const foundVote = voteResult.first === item.id || voteResult.second === item.id || voteResult.third === item.id;
                    if (foundVote) {
                        item.voted = true;
                    }
                }
                
            });

            return processedData
        });


    return result;
}

export { getAwardsCategories };

async function getAwardsCategoriesVoted(currentUser: User, awardId: string) {

    const voteResult: any = await getVotesForUser(currentUser, awardId);

    let categories = await memoizedGetAwardsCategories(currentUser, awardId);
    let processedData = await categories.map((item: any) => ({ ...item, voted: false }));
    const votedCategoriesIds = Object.keys(voteResult);

    processedData.forEach((item: any) => {
        if (votedCategoriesIds.includes(item.id)) {
            item.voted = true;
        }
    });
   
    return processedData;
}

export { getAwardsCategoriesVoted };

async function getSingleItem(currentUser: User, awardId: string) {

    const options = await getOptions(currentUser, 'get');

    return fetch(process.env.REACT_APP_FOBOS_API_HOST + '/awards/' + awardId, options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        });
        
}

export { getSingleItem }

async function getVotesForUser(currentUser: User, awardId: string) {

    const options = await getOptions(currentUser, 'get');

    return fetch(process.env.REACT_APP_FOBOS_API_HOST + '/awards/' + awardId + "/voted", options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        });
        
}

export { getVotesForUser }

async function getNomineesByCategory(currentUser: User, awardId: string, categoryId: string) {

    const options = await getOptions(currentUser, 'get');

    return fetch(`${process.env.REACT_APP_FOBOS_API_HOST}/awards/${awardId}/categories/${categoryId}/nominees`, options)
        .then((response) => {
            return response.json();
        })
        .then(async (data) => {
            /*
            for (let i = 0; i < data.length; i++) {
                if (data[i].type === "room") {
                    data[i].room = await getSingleRoom(currentUser, data[i].room_id);
                }else{
                    data[i].game = await getSingleGame(currentUser, data[i].game_id);
                    data[i].room = await getSingleRoom(currentUser, data[i].game.room_id);
                }
            }
            */
            
            return data;
        });
        
}

export { getNomineesByCategory }


async function getPlayedNomineesForUser(currentUser: User, awardId: string, categoryId: string) {

    const options = await getOptions(currentUser, 'get');

    return fetch(`${process.env.REACT_APP_FOBOS_API_HOST}/awards/${awardId}/categories/${categoryId}/played`, options)
    .then((response) => {
        return response.json();
    })
    .then(async (data) => {        
        return data;
    });
        
}

export { getPlayedNomineesForUser }

async function postVoting(currentUser: User, awardId: string, categoryId: string, voting: iVoting) {

    const options = await getOptions(currentUser, 'post', voting);

    return fetch(`${process.env.REACT_APP_FOBOS_API_HOST}/awards/${awardId}/categories/${categoryId}/vote`, options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return {...data, status:201};
        });
}

export { postVoting }

// Memoized section
export const memoizedGetFullList = memoizee(getFullList, { promise: true, maxAge: 7*24*3600});
export const memoizedGetAwardsCategories = memoizee(getAwardsCategories, { promise: true, maxAge: 7*24*3600});
export const memoizedGetSingleItem = memoizee(getSingleItem, { promise: true, maxAge: 7*24*3600});
export const memoizedGetNomineesByCategory = memoizee(getNomineesByCategory, { promise: true, maxAge: 7*24*3600});