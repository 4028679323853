import { User } from "firebase/auth";

export async function getOptions (user: User, method: string, body?: any){
    
    const currentToken = await user.getIdToken();

    let options: any = {
        method: method.toUpperCase(),
        headers: {
            'Content-Type': 'application/json',
            //'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': `Bearer ${currentToken}`
        }    
    }

    if (body){
        options = {
            ...options,
            body: JSON.stringify(body)
        }
    }

    return options;
}