import { useState, useEffect, useContext } from 'react';

// Chakra imports

import { Box, Flex, Grid, Text, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink } from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons';

//Data presentation
import { AuthContext } from 'context/auth-context';
import { useParams } from 'react-router-dom';
import { iAwards } from 'types/iAwards';
//import { iProfile } from 'types/iProfile';
import { getAwardsCategoriesVoted, memoizedGetSingleItem } from 'services/awardsService';
import { iAwardsCategories } from 'types/iAwardsCategories';

// Custom components
import Category from 'components/card/Category';
import AwardBanner from './components/AwardBanner';

export default function Vote() {
	const params: any = useParams();
	const awardId: string = params['award_id'] || "";

	const { currentUser, currentProfile } = useContext(AuthContext);	

	// Chakra Color Mode
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	
	const [awardsCategories, setAwardsCategories]: [iAwardsCategories[], (awards: iAwardsCategories[]) => void] = useState();
	const [award, setAward]: [iAwards, (award: iAwards) => void] = useState();

	useEffect(() => {		
			
			getAwardsCategoriesVoted(currentUser, awardId).then((awardsCategories: iAwardsCategories[]) => {
				setAwardsCategories(awardsCategories);
			});

			memoizedGetSingleItem(currentUser, awardId).then((award: iAwards) => {
				setAward(award);
			});

			
	}, [currentProfile, currentUser, awardId]);


	return (
		<Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
			<Flex mb="50px">
				<ChakraLink as={ReactRouterLink} to={`/awards/default`}>
					<ArrowBackIcon/>Volver al listado de votaciones
				</ChakraLink>
			</Flex>

			{/* Main Fields */}		
			{ award ? <AwardBanner award={award} /> : null }
			
			<Grid
				mb='20px'
				//gridTemplateColumns={{ xl: 'repeat(3, 1fr)', '2xl': '1fr 0.46fr' }}
				gap={{ base: '20px', xl: '20px' }}
				display={{ base: 'block', xl: 'grid' }}>
				<Flex flexDirection='column' gridArea={{ xl:  '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }} >
					<Flex direction='column'>
						<Flex
							mt='45px'
							mb='20px'
							justifyContent='space-between'
							direction={{ base: 'column', md: 'row' }}
							align={{ base: 'start', md: 'center' }}>
							<Text color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
								Categorías
							</Text>
							
						</Flex>
						
						<SimpleGrid columns={{ base: 1, md: 4 }} gap='20px'>
						{awardsCategories?.map((awardCategory: iAwardsCategories) => (	
							<Category
								image={process.env.REACT_APP_CDN_PUBLIC_IMAGES + "uploads/awards/" + (awardCategory?.image || award?.logo || "")}
								name={awardCategory.name}
								id={awardCategory.id}
								voted={awardCategory.voted}
								awardId={awardId}
							/>
							))}
						</SimpleGrid>
					
				</Flex>
			</Flex>
			
		</Grid>
		{/* Delete Product */}
	</Box>
	);
}
