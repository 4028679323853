import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

// Chakra imports
import { Button, Flex, Select, Text, useColorModeValue } from '@chakra-ui/react';

import { AuthContext } from 'context/auth-context';

// Custom components
import Card from 'components/card/Card';

//Types
import { iNominee } from 'types/iNominee';
import { iVoting } from 'types/iVoting';

//Services
import { getPlayedNomineesForUser, postVoting } from 'services/awardsService';

export default function SideVoting(props: { nominees: iNominee[],votings?: iVoting, [x: string]: any }) {
	const { nominees, votings, ...rest } = props;

	const { currentUser, currentProfile } = useContext(AuthContext);
	const params: any = useParams();
	const awardId: string = params['award_id'] || "";
	const categoryId: string = params['category_id'] || "";
	
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const [firstVoted, setFirstVoted]: [string, (nomineeId: string) => void] = useState("-1");
	const [secondVoted, setSecondVoted]: [string, (nomineeId: string) => void] = useState("-1");
	const [thirdVoted, setThirdVoted]: [string, (nomineeId: string) => void] = useState("-1");
	const [votableNominees, setVotableNominees]: [iNominee[], (awards: iNominee[]) => void] = useState();
	const [firstVotableNominees, setFirstVotableNominees]: [iNominee[], (awards: iNominee[]) => void] = useState()
	const [secondVotableNominees, setSecondVotableNominees]: [iNominee[], (awards: iNominee[]) => void] = useState()
	const [thirdVotableNominees, setThirdVotableNominees]: [iNominee[], (awards: iNominee[]) => void] = useState();
	const [alreadyVoted, setAlreadyVoted]: [boolean, (alreadyVoted: boolean) => void] = useState(false);

	const setFristCombo = (e: any) => {
		const value = e.target.value;
		setFirstVoted(value);
		// Reset combo value
		let newSecondVotableNominees;
		let newThirdVotableNominees;
		if (value === "-1") {
			//Values at combo 2 are all except value at combo 3
			newSecondVotableNominees = votableNominees?.filter((nominee: iNominee) => (nominee.id !== thirdVoted));
			//Values at combo 3 are all except value at combo 2
			newThirdVotableNominees = votableNominees?.filter((nominee: iNominee) => (nominee.id !== secondVoted));
			//Values at combo 1 are all except value at combo 2 and 3
			setFirstVotableNominees(votableNominees?.filter((nominee: iNominee) => (nominee.id !== secondVoted && nominee.id !== thirdVoted)));
		}else{
			// Values at combo 2 are all except value at combo 1 and value at combo 3
			newSecondVotableNominees = votableNominees?.filter((nominee: iNominee) => (nominee.id !== value && nominee.id !== "-1" && nominee.id !== thirdVoted));
			// Values at combo 3 are all except value at combo 1 and value at combo 2	
			newThirdVotableNominees = votableNominees?.filter((nominee: iNominee) => (nominee.id !== value && nominee.id !== "-1" && nominee.id !== secondVoted));
		}
		
		setSecondVotableNominees(newSecondVotableNominees);
		setThirdVotableNominees(newThirdVotableNominees);
	}

	const setSecondCombo = (e: any) => {
		const value = e.target.value;
		setSecondVoted(value);
		// Reset combo value
		let newFirstVotableNominees;
		let newThirdVotableNominees;
		if (value === "-1") {
			//Values at combo 1 are all except value at combo 3
			newFirstVotableNominees = votableNominees?.filter((nominee: iNominee) => (nominee.id !== thirdVoted));
			//Values at combo 3 are all except value at combo 1
			newThirdVotableNominees = votableNominees?.filter((nominee: iNominee) => (nominee.id !== firstVoted));
			setSecondVotableNominees(votableNominees?.filter((nominee: iNominee) => (nominee.id !== firstVoted && nominee.id !== thirdVoted)));
		} else {
			// Values at combo 1 are all except value at combo 2 and value at combo 3
			newFirstVotableNominees = votableNominees?.filter((nominee: iNominee) => (nominee.id !== value && nominee.id !== "-1" && nominee.id !== thirdVoted));
			// Values at combo 3 are all except value at combo 1 and value at combo 2	
			newThirdVotableNominees = votableNominees?.filter((nominee: iNominee) => (nominee.id !== value && nominee.id !== "-1" && nominee.id !== firstVoted));
		}

		setFirstVotableNominees(newFirstVotableNominees);
		setThirdVotableNominees(newThirdVotableNominees);

	}

	const setThirdCombo = (e: any) => {
		const value = e.target.value;
		setThirdVoted(value);
		// Reset combo value
		let newFirstVotableNominees;
		let newSecondVotableNominees;
		if (value === "-1") {
			//Values at combo 1 are all except value at combo 2
			newFirstVotableNominees = votableNominees?.filter((nominee: iNominee) => (nominee.id !== secondVoted));
			//Values at combo 2 are all except value at combo 1
			newSecondVotableNominees = votableNominees?.filter((nominee: iNominee) => (nominee.id !== firstVoted));
			setSecondVotableNominees(votableNominees?.filter((nominee: iNominee) => (nominee.id !== firstVoted && nominee.id !== secondVoted)));
		} else {
			// Values at combo 1 are all except value at combo 2 and value at combo 3
			newFirstVotableNominees = votableNominees?.filter((nominee: iNominee) => (nominee.id !== value && nominee.id !== "-1" && nominee.id !== secondVoted));
			// Values at combo 2 are all except value at combo 1 and value at combo 3	
			newSecondVotableNominees = votableNominees?.filter((nominee: iNominee) => (nominee.id !== value && nominee.id !== "-1" && nominee.id !== firstVoted));
		}
		setFirstVotableNominees(newFirstVotableNominees);
		setSecondVotableNominees(newSecondVotableNominees);
	}

	const executeVoting = async () => {
		let votes: iVoting = {
			first: null,
			second: null,
			third: null
		};

		if (firstVoted !== "-1") {
			votes.first = firstVoted;
		}
		if (secondVoted !== "-1") {
			votes.second = secondVoted;
		}
		if (thirdVoted !== "-1") {
			votes.third = thirdVoted;
		}

		await postVoting(currentUser, awardId, categoryId, votes).then((response: any) => {
			if (response.status === 201) {
				// Show success message
				alert("Votación realizada con éxito");
				setAlreadyVoted(true);
				props.onChangesInChild({voted:true, votes:votes});
			} else {
				// Show error message
				alert("Ocurrió un error al realizar la votación");
			}
		});

	}

	useEffect(() => {		
		getPlayedNomineesForUser(currentUser, awardId, categoryId).then((playedNominees: string[]) => {
			const votableNominees = (categoryId !== "6" ? nominees?.filter((nominee: iNominee) => playedNominees.includes(nominee.id)): nominees);
			setVotableNominees(votableNominees);
			setFirstVotableNominees(votableNominees);
			setSecondVotableNominees(votableNominees);
			setThirdVotableNominees(votableNominees);


			if (votings){
				setFirstVoted(votings.first);
				setSecondVoted(votings.second);
				setThirdVoted(votings.third);
				setAlreadyVoted(true);
			}
		});

}, [ currentProfile, currentUser, awardId, categoryId,nominees, votings ]);

	return (
		<Card mb='20px' {...rest}>
			<Flex align='center' w='100%' justify='space-between' mb='30px'>
				<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mb='4px'>
					Votaciones
				</Text>
				
			</Flex>
			<Flex align='center' w='100%' justify='space-between' mb='30px'>
				<Text color={textColorPrimary} fontWeight='light'  mb='4px'>
					Recuerda que solo podrás votar aquellas salas/juegos que hayas jugado y hayan sido verificadas previamente.
				</Text>
			</Flex>
			<Flex align='center' w='100%' justify='space-between' mb='30px'>
				<Text color="red" fontWeight='light'  mb='4px' display={(votableNominees?.length > 0?"none":"block")} >
					No puedes votar en esta categoría ya que no hay juegos registrados para los nominados de esta categoría.
				</Text>
				<Text color="green" fontWeight='bold'  mb='4px' display={(alreadyVoted ? "block":"none")} >
					Ya has votado en esta categoría.
				</Text>
			</Flex>
			<Text key="first_place">Primer Puesto</Text>
			<Select fontWeight='700' id="first_voted" value={firstVoted} disabled={alreadyVoted || !(votableNominees?.length >= 1)}  onChange={setFristCombo}>
				<option value="-1">- Selecciona - </option>
				
				{firstVotableNominees?.map((nominee: iNominee) => (	
					<option value={nominee?.id}>{nominee?.game?.name || nominee?.room?.name}</option>
				))}
			</Select>
			<br/>
			
			<Text key="second_place">Segundo Puesto</Text>
			<Select fontWeight='700' id="second_vote"  value={secondVoted} disabled={alreadyVoted || !(votableNominees?.length >= 2)} onChange={setSecondCombo}>
				<option value="-1">- Selecciona - </option>
				{secondVotableNominees?.map((nominee: iNominee) => (	
					<option value={nominee?.id}>{nominee?.game?.name || nominee?.room?.name}</option>
				))}
			</Select>
			<br/>
			<Text key="third_place">Tercer Puesto</Text>
			<Select fontWeight='700' id="third_vote"  value={thirdVoted} disabled={alreadyVoted || !(votableNominees?.length >= 3)} onChange={setThirdCombo}>
				<option value="-1">- Selecciona - </option>
				{thirdVotableNominees?.map((nominee: iNominee) => (	
					<option value={nominee?.id}>{nominee?.game?.name || nominee?.room?.name}</option>
				))}
			</Select>
			<br/><br/>
			<Button
				variant='darkBrand'
				color='white'
				fontSize='sm'
				fontWeight='500'
				borderRadius='70px'
				px='24px'
				py='5px'
				disabled={alreadyVoted || !(votableNominees?.length > 0)}
				onClick={executeVoting}
				>
				Votar
			</Button>
		</Card>
	);
}
