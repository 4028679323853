import { useHistory } from 'react-router-dom';
// Chakra imports
import { Box, Flex, Text, useColorModeValue, Badge,  Button, } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import IconBox from 'components/icons/IconBox';
import { AuthContext } from 'context/auth-context';
import { useContext, useLayoutEffect, useState } from 'react';
// Assets
//import { MdOutlineTimer } from 'react-icons/md';
import { memoizedGetAwardsCategories } from 'services/awardsService';
import { iAwardsCategories } from 'types/iAwardsCategories';
//import { iProfile } from 'types/iProfile';

export default function Course(props: {
	icon: JSX.Element;
	title: string;
	desc: string;
	date: string;
	day: string;
	time: string;
	bgBox: string;
	logo?: string;
	id?: string;
}) {
	const { icon, title, desc, date, day, bgBox, id } = props;
	//const textColor = useColorModeValue('navy.700', 'white');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textBrand = useColorModeValue('brand.500', 'white');
	const bgBadge = useColorModeValue('secondaryGray.300', 'whiteAlpha.50');

	const history = useHistory();
	
	const { currentUser, currentProfile } = useContext(AuthContext);
	//const [localUser, setLocalUser]: [iProfile, (attr: iProfile) => void] = useState(currentProfile);
	const [awardsCategories, setAwardsCategories]: [iAwardsCategories[], (awards: iAwardsCategories[]) => void] = useState();
	
	useLayoutEffect(() => {		
		/*
		getAwardsCategories(currentUser, id).then((awardsCategories: iAwardsCategories[]) => {
			setAwardsCategories(awardsCategories);
		});
		*/

		memoizedGetAwardsCategories(currentUser, id).then((awardsCategories: iAwardsCategories[]) => {
			setAwardsCategories(awardsCategories);
		});
	}, [currentProfile, currentUser, id]);

	const goToAward = (awardId: string) => {
		history.push(`/awards/vote/${awardId}`)
	}

	return (
		<Card p='20px' h='max-content' minH={{ md: '450px', xl: 'auto' }}>
			<Flex direction={{ base: 'column', md: 'column', xl: 'row' }}>
				<IconBox
					bg={bgBox}
					icon={icon}
					minW={{ base: '100%', xl: '270px' }}
					minH={{ base: '200px', xl: '270px' }}
					borderRadius='20px'
					me='34px'
				/>
					
				
				<Flex
					justify='space-between'
					flexDirection='column'
					mb='auto'
					py='30px'
					pb={{ base: '0px', md: '0px' }}>
					<Flex display={{ base: 'block', xl: 'flex' }}>
						<Box flexDirection='column' w={{ xl: '68%' }} mb='25px'>
							<Text
								color={textColor}
								fontSize={{
									base: 'xl',
									md: 'xl',
									xl: 'xl',
									'2xl': '28px'
								}}
								mb='10px'
								fontWeight='700'>
								{title}
							</Text>
							<Text
								color='secondaryGray.600'
								fontSize={{
									base: 'md'
								}}
								fontWeight='400'
								me='14px'>
								{desc}
							</Text>
						</Box>
						<Text
							ms='auto'
							mt='10px'
							color='secondaryGray.600'
							fontSize={{
								base: 'md'
							}}
							fontWeight='500'>
							{day} •{' '}
							<Text
								as='span'
								color={textColor}
								fontSize={{
									base: 'md'
								}}
								fontWeight='500'
								ms='4px'>
								{date}
							</Text>
						</Text>
					</Flex>
					<Flex w='100%' flexWrap='wrap'>
						Categorías:
					</Flex>
					<Flex w='100%' flexWrap='wrap'>

						{awardsCategories?.map((category) => (
							<Badge
								key={category.id}
								bg={bgBadge}
								textAlign='center'
								mb={{ base: '20px', md: '5px' }}
								color={textBrand}
								me='10px'
								h='max-content'
								>
								{category.name}
							</Badge>
						))}
						
						
					</Flex>
					<br/>
					<Button variant='darkBrand'
								color='white'
								fontSize='sm'
								fontWeight='500'
								borderRadius='70px'
								px='24px'
								py='5px'
								onClick={() => goToAward(id)}
								>
								Vota ya
						</Button>	
				</Flex>
				
			</Flex>
		</Card>
	);
}
/*
function useEffect(arg0: () => void, arg1: (import("@firebase/auth").User | iProfile)[]) {
	throw new Error('Function not implemented.');
}

*/