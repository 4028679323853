import { User } from "firebase/auth";
import { iTeam } from "types/iTeam";

async function getCurrentUser(currentUser: User) {

    const currentToken = await currentUser.getIdToken(true);

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        }
    }

    return fetch(process.env.REACT_APP_FOBOS_API_HOST + '/users/me', options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        });

}
export { getCurrentUser };

async function getCurrentUserTeams(currentUser: User) {

    const currentToken = await currentUser.getIdToken(true);

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        }
    }

    let finalTeams: iTeam[] = [];
    const teamsOwnership = await fetch(process.env.REACT_APP_FOBOS_API_HOST + '/users/me/teams/ownership', options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        });
    const teamsMembership = await fetch(process.env.REACT_APP_FOBOS_API_HOST + '/users/me/teams/membership', options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        });

    if (teamsOwnership.length > 0){
        finalTeams = [...finalTeams, ...teamsOwnership];
    }
        
    if (teamsMembership.length > 0){
        finalTeams = [...finalTeams, ...teamsMembership];
    }

    return finalTeams;
}
export { getCurrentUserTeams };

async function getCurrentUserTeamsInvites(currentUser: User) {

    const currentToken = await currentUser.getIdToken(true);

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FOBOS_API_KEY,
            'Authorization': 'Bearer ' + currentToken
        }
    }

    return await fetch(process.env.REACT_APP_FOBOS_API_HOST + '/users/me/teams/pending-invites', options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        });
        
}
export { getCurrentUserTeamsInvites };