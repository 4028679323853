// Chakra imports
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Flex, Link, Text, useColorModeValue } from '@chakra-ui/react';
import { iAwards } from 'types/iAwards';

// Assets
//import banner from 'assets/img/nfts/NftBanner1.png';

export default function AwardBanner(
	props: {
		award: iAwards;
	}
) {
	const { award } = props;
	let sidebarBg = useColorModeValue('white', 'fobos.cardGrey');
	let textColor = useColorModeValue('secondaryGray.900', 'white');
	// Chakra Color Mode
	return (

		<Flex
			direction='column'
			bgImage={process.env.REACT_APP_CDN_PUBLIC_IMAGES + "uploads/awards/" + award.logo}
			bgColor={sidebarBg}
			bgSize='contain'
			bgPosition='right'
			
			backgroundRepeat='no-repeat'
			py={{ base: '30px', md: '56px' }}
			px={{ base: '30px', md: '64px' }}
			borderRadius='30px'>
			<Text
				fontSize={{ base: '24px', md: '34px' }}
				color={textColor}
				mb='14px'
				maxW={{
					base: '100%',
					md: '64%',
					lg: '46%',
					xl: '70%',
					'2xl': '50%',
					'3xl': '42%'
				}}
				fontWeight='700'
				lineHeight={{ base: '32px', md: '42px' }}>
				{award.name}
			</Text>
			<Text
				
				color={textColor}
				maxW={{
					base: '100%',
					md: '64%',
					lg: '40%',
					xl: '56%',
					'2xl': '46%',
					'3xl': '34%'
				}}
				fontWeight='500'
				mb='40px'
				>
					<div dangerouslySetInnerHTML={{__html: award.description}}></div>
				
			</Text>
			<Flex align='center'>
				
				<Link href={award.web} isExternal>
					<Text color={textColor} fontSize='sm' fontWeight='500'>
						Visita la web <ExternalLinkIcon mx='2px' />
					</Text>
				</Link>
			</Flex>
		</Flex>
	);
}
