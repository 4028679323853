import React, { Component} from 'react';
import ReactDOM from 'react-dom'; 
import { ColorModeScript } from '@chakra-ui/react'
import './assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/awards';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import { AuthIsNotSignedIn, AuthIsSignedIn, AuthProvider } from 'context/auth-context';
import ReactGA from 'react-ga4';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
//import { StaticDataProvider } from 'context/static-data-context';

const SignInRoute: React.FunctionComponent = () => (
	<HashRouter>
		<Switch>
			<Route path={`/auth`} component={AuthLayout} />
			<Redirect to='/auth' />
		</Switch>
	</HashRouter>
)

const MainRoute: React.FunctionComponent = () => (
	<HashRouter>
		<Switch>
			<Route path={`/awards`} component={AdminLayout} />
			<Redirect from='/' to='/awards' />
		</Switch>
	</HashRouter>
)

class App extends Component {
	//useEffect(() => {
	//	ReactGA.send({ hitType: "pageview", page: window.location.pathname });
	 // }, [])

	componentDidMount(){
		
			ReactGA.send({ hitType: "pageview", page: window.location.hash.substring(1)});
		  
	}
	render() {
		return (
			<>
				<ColorModeScript initialColorMode={theme.config.initialColorMode} />
				<ChakraProvider theme={theme}>
					<React.StrictMode>
						<AuthProvider>
							<AuthIsSignedIn>
								
									<MainRoute /> 
								
							</AuthIsSignedIn>
							<AuthIsNotSignedIn>
								<SignInRoute />
							</AuthIsNotSignedIn>
						</AuthProvider>
					</React.StrictMode>
				</ChakraProvider>
			</>
		);
	}
}

ReactDOM.render(<App />, document.getElementById('root'));

/*
ReactDOM.render(
	<>
		<ColorModeScript initialColorMode={theme.config.initialColorMode} />
		<ChakraProvider theme={theme}>
			<React.StrictMode>
				<AuthProvider>
					<AuthIsSignedIn>
						
							<MainRoute /> 
						
					</AuthIsSignedIn>
					<AuthIsNotSignedIn>
						<SignInRoute />
					</AuthIsNotSignedIn>
				</AuthProvider>
			</React.StrictMode>
		</ChakraProvider>
	</>,
	document.getElementById('root')
);
*/