// Chakra imports
import { AvatarGroup, Box, Button, Flex, Image, Text, useColorModeValue, SimpleGrid, useDisclosure } from '@chakra-ui/react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
  } from '@chakra-ui/react'
// Custom components
import Card from 'components/card/Card';
// Assets
//import { useState } from 'react';
/*
import { IoHeart, IoHeartOutline } from 'react-icons/io5';
import { iGame } from 'types/iGame';
*/
import { iRoom } from 'types/iRoom';

export default function RoomNominee(props: {
	room: iRoom;
	variant?: string
}) {
	const { room, variant } = props;
	//const [ like, setLike ] = useState(false);
	const textColor = useColorModeValue('navy.700', 'white');
	const textColorBid = useColorModeValue('brand.500', 'white');


	const { isOpen, onOpen, onClose } = useDisclosure();
	const showOverlay = () => {
		/*
		getSingleRoom(currentUser, playedGame.room_id).then((room: iRoom) => {
		  setRoom(room);
		});
		getSingleGame(currentUser, playedGame.game_id).then((game: iGame) => {
		  setGame(game);
		});
		*/
		onOpen();
	  }


	return (
		<>
		<Card p='20px' id={room.id} variant={variant || ""}>
			<Flex direction={{ base: 'column' }} justify='center'>
				<Box mb={{ base: '20px', '2xl': '20px' }} position='relative'>
					<Image
						src={process.env.REACT_APP_CDN_PUBLIC_IMAGES +room.image}
						w={{ base: '100%', '3xl': '100%' }}
						h={{ base: '100%', '3xl': '100%' }}
						borderRadius='20px'
					/>
				</Box>
				<Flex flexDirection='column' justify='space-between' h='100%'>
					<Flex
						justify='space-between'
						direction={{
							base: 'row',
							md: 'column',
							lg: 'row',
							xl: 'column',
							'2xl': 'row'
						}}
						mb='auto'>
						<Flex direction='column'>
							<Text
								color={textColor}
								fontSize={{
									base: 'xl',
									md: 'lg',
									lg: 'lg',
									xl: 'lg',
									'2xl': 'md',
									'3xl': 'lg'
								}}
								mb='5px'
								fontWeight='bold'
								me='14px'>
								{room.name}
							</Text>
							<Text
								color='secondaryGray.600'
								fontSize={{
									base: 'sm'
								}}
								fontWeight='400'
								me='14px'>
								{room.name}
							</Text>
						</Flex>
						<AvatarGroup
							max={3}
							color={textColorBid}
							size='sm'
							mt={{
								base: '0px',
								md: '10px',
								lg: '0px',
								xl: '10px',
								'2xl': '0px'
							}}
							fontSize='12px'>
							
						</AvatarGroup>
					</Flex>
					<Flex
						align={{
							base: 'center',
							md: 'start',
							lg: 'center',
							xl: 'start',
							'2xl': 'center'
						}}
						justify='space-between'
						direction={{
							base: 'row',
							md: 'column',
							lg: 'row',
							xl: 'column',
							'2xl': 'row'
						}}
						mt='25px'>
						
							<Button
								onClick={showOverlay}
								variant='darkBrand'
								color='white'
								fontSize='sm'
								fontWeight='500'
								borderRadius='70px'
								px='24px'
								py='5px'>
								Saber más
							</Button>
						
					</Flex>
				</Flex>
			</Flex>
		</Card>
		<Modal isOpen={isOpen} onClose={onClose} isCentered size='xl'>
				<ModalOverlay />
				<ModalContent>
				<ModalHeader>Información sobre <i>{room.name}</i></ModalHeader>
				<ModalCloseButton />
				<ModalBody css={'text-align:center'}>
					<Image display={'inline-block'} srcSet={process.env.REACT_APP_CDN_PUBLIC_IMAGES + room.image} />
					<Text>
						{room.description}
					</Text>
					<SimpleGrid columns={{ base: 2, md: 4 }} gap={{ base: '0px', md: '20px' }}>
					
					</SimpleGrid>
				</ModalBody>

				<ModalFooter>
					<Button colorScheme='yellow' mr={3} onClick={onClose}>
					Cerrar
					</Button>
					
				</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
