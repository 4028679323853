export const textStyles = {
	components: {
		Text: {
			variants: {
				award_banner: (props: any) => ({
					field: {
						whiteSpace: 'normal',
						color: 'red'
					}
				}),
				
			}
		}
	}
};
